/* custom.css */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Bungee+Inline&family=Bungee+Spice&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    --primary-color: #010A26;
    --accent-color1: #03258C;
    --accent-color2: #1243A6;
    --accent-color3: #0F468C;
    --accent-color4:  #66CDD9;
    --font-color: white;
  }
  .profile-image {
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.3);
  }
  
  i {
    font-size: 24px; /* Adjust the size */
    margin-right: 10px; /* Add some spacing between the icon and the text */
  }
  
  .header {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 48px;
    
  }
  .desc {
    font-weight: 300;
    line-height: 1.6;
  }
  .skill-list div {
    display: flex;
    align-items: center; /* Vertically center the icon and text */
    gap: 10px; /* Add some space between the icon and the text */
    margin-bottom: 20px; /* Add some space between each item */
  }
  
  .skill-list i {
    min-width: 24px; /* Ensure icons have a consistent width */
  }
  
  .icon {
    margin-left: 5px;
  }
   /* .skill {
    font-family: "Bungee Inline", sans-serif;
    font-weight: 400;
    font-style: normal;
   } */
  /* Navbar Styles */

  .name{
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 64px;
  }
  .navbar-custom {
    background-color: var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-custom .navbar-brand {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    
  }

  .navbar-custom .nav-link {
    color: var(--font-color);
  }
  
  .navbar-custom .navbar-brand:hover,
  .navbar-custom .nav-link:hover {
    color: var(--accent-color4);
  }
  
  /* Button Styles */
  .btn-primary {
    background-color: var(--accent-color2);
    border-color: var(--accent-color2);
  }
  
  .btn-primary:hover {
    background-color: var(--accent-color4);
    border-color: var(--accent-color4);
  }
  
  .btn-secondary {
    background-color: black;
    border-color: black;
  }
  
  .btn-secondary:hover {
    background-color: var(--accent-color2);
    border-color: var(--accent-color2);
  }
  
  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    color: var(--font-color);
  }
  
  a, a:hover {
    color: var(--accent-color4);
    text-decoration: none;
  }
  
  /* Utility Classes */
  .text-primary {
    color: var(--primary-color) !important;
  }
  
  .bg-primary {
    background-color: var(--primary-color) !important;
  }
  
  /* Section Styles */
  section {
    padding: 60px 0;
    color: var(--font-color);
  }

  #app {
    background-color: var(--primary-color);
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }



  /* Target the icons within the skill sections */
  /* i:hover {
    text-shadow: 0 0 5px #ffff00, 0 0 10px #ffff00, 0 0 15px #ffff00, 0 0 20px #ffea00, 0 0 35px #ffea00;
  } */
  

  
  .project-card {
    transition: transform 0.2s; /* Animation */
    box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.6); /* White glow effect */
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .project-card:hover {
    transform: scale(1.05); /* Slightly scale up cards on hover */
  }
  .custom-card-img {
    height: 200px; /* Example fixed height, adjust as needed */
    object-fit: cover; /* Crop to fit without stretching */
    width: 100%; /* Ensure the image covers the full width of the card */
  }
  .card-body {
    flex-grow: 1; /* Allows the card body to fill the available space */
    display: flex;
    flex-direction: column;
  }
  
  .card-title, .card-text {
    overflow: hidden; /* Prevent text overflow */
  }
  
  .card-text:last-child {
    margin-top: auto; /* Pushes the last text element and button to the bottom */
  }
  
  .button-container {
    margin-top: auto; /* Ensure buttons are aligned at the bottom */
  }

  /* Adjustments for small screens */
  @media (max-width: 768px) {
    /* .navbar-custom {
      background-color: var(--primary-color);
    }
  
    .navbar-custom .navbar-toggler {
      background-color: var(--primary-color);
    }
  
    .navbar-custom .navbar-toggler-icon {
      filter: invert(1);
    } */
    .header {
      font-size: 32px; /* Default font size */
      white-space: nowrap; /* Prevent wrapping */
    }
    .profile-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    .name{
      font-size: 56px;
    }

  }

  .icon-link {
    color: #333; /* Or any color you prefer */
    margin-right: 10px; /* Adjust spacing to the right */
    /* Add hover effect if desired */
    transition: color 0.2s;
  }
  
  .icon-link:hover {
    color: var(--accent-color4); /* Or any hover color you prefer */
  }
  
